<template>
  <div class="map" style="flex:1">
    <div ref="mapBox" style="width:100%; min-height:300px;height:calc( 100vh - 80px - 48px - 30px )" />
  </div>
</template>
<script>
const TMap = window.TMap
export default {
  data() {
    return {
      param: {},
      TXMapParams: {
        map: null, // 地图实例 （地图）
        marker: '', // 地图的标识（标注的点）（地图）
        appkey: this.$key, // appkey是开发者key（地图）
        increaseZB: { // 这个参数是地图的经纬度（地图）
          lat: '', // 纬度
          lng: '' // 经度
        }
      }
    }
  },
  created() {
    this.param = this.$getParams()
    this.TXMapParams.increaseZB.lat = this.param.latitude
    this.TXMapParams.increaseZB.lng = this.param.longitude
  },
  mounted() {
    this.TXMapParams.map || this.initMap()
  },

  methods: {
    initMap() {
      const myLatLng = this.createZuoBiao(this.TXMapParams.increaseZB.lat, this.TXMapParams.increaseZB.lng)
      this.TXMapParams.map = new TMap.Map(this.$refs.mapBox, { // 实例化地图，赋值给data中的map
        center: myLatLng, // 目前的位置
        zoom: 13,
        draggable: true, // 是否可移动
        scrollwheel: true, // 是否可滚动
        disableDoubleClickZoom: false
      })

      const markerLayer = new TMap.MultiMarker({
        map: this.TXMapParams.map // 指定地图容器
      })

      markerLayer.add([{
        'id': '3', // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        'styleId': 'myStyle', // 指定样式id
        'position': new TMap.LatLng(this.TXMapParams.increaseZB.lat, this.TXMapParams.increaseZB.lng), // 点标记坐标位置
        'properties': { // 自定义属性
          'title': 'marker3'
        }
      }
      ])

      new TMap.InfoWindow({
        map: this.TXMapParams.map,
        position: myLatLng,
        // 设置infoWindow，content支持直接传入html代码，以实现各类内容格式需求
        content: `<div style='padding:5px;font-size:12px;'>
        <div style='margin-bottom:5px'>${this.param.name}</div><div style='margin-bottom:5px'>电话：${this.param.tel}</div><div>地址：${this.param.address}</div>
        </div>`
      })
    },
    // 创建经纬度
    createZuoBiao(myLatitude, myLongitude) {
      return new TMap.LatLng(myLatitude, myLongitude)
    }
  }
}
</script>

<style lang="scss" scoped>
  .map{
    width: 1300px;
    margin:15px auto;
  }
</style>
